<template>
  <svg width="156" height="156" viewBox="0 0 156 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="77.9995" cy="78" rx="56.2232" ry="57.0181" stroke="#5F9E45" stroke-opacity="0.46" stroke-width="14"/>
    <g clip-path="url(#clip0_3432_4650)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M107 57.2883C107.818 58.1135 108.277 59.2325 108.277 60.3992C108.277 61.566 107.818 62.685 107 63.5102L72.1122 98.7117C71.2944 99.5366 70.1853 100 69.0289 100C67.8726 100 66.7635 99.5366 65.9457 98.7117L48.5015 81.1109C47.7071 80.281 47.2676 79.1696 47.2775 78.0158C47.2874 76.8621 47.7461 75.7585 48.5547 74.9427C49.3632 74.1268 50.457 73.6641 51.6005 73.654C52.7439 73.644 53.8455 74.0875 54.668 74.8891L69.0289 89.3789L100.834 57.2883C101.652 56.4634 102.761 56 103.917 56C105.074 56 106.183 56.4634 107 57.2883Z" fill="#378F10"/>
    </g>
    <defs>
      <clipPath id="clip0_3432_4650">
        <rect width="60" height="60" fill="white" transform="translate(47.7773 48)"/>
      </clipPath>
    </defs>
  </svg>
</template>
