<script setup>

import texts from "~/configs/texts";

const emits = defineEmits(['change'])

const props = defineProps({
  error: String
})

const expandAgreements = ref(false);
const statementAccepted = ref([false]);
const allAccepted = computed(() => statementAccepted.value.every(v => v))

const acceptAll = (v) => {
  statementAccepted.value = statementAccepted.value.map(i => v);
}

onMounted(() => {
  statementAccepted.value = [];
  texts.digital.agreements.forEach(() => statementAccepted.value.push(false))
  texts.digital.statements.forEach(() => statementAccepted.value.push(false))
});

watch(() => allAccepted.value, (v) => emits('change', v), {deep: true})

</script>

<template>
  <div>
    <div class="pb-4 pt-8 font-extralight text-xl">Oświadczenia i pełnomocnictwa</div>
    <div class="text-xs font-extralight">Poniżej znajdziesz oświadczenia i pełnomocnictwa niezbędne do realizacji zamówienia. Zapoznaj się z ich treścią, zanim je zaakceptujesz.</div>
    <div class="pt-4">
      <InputCheckBox @change="acceptAll"
                     :checked="allAccepted"
                     :error="error"
                     black>Akceptuję i zaznaczam wszystkie
        <ButtonToggle @toggle="(e) => expandAgreements = e">
          <template v-slot:default>
            <span class="flex ml-4"><span>rozwiń wszystkie</span> <IconChevronDown class="!w-3 !h-3 fill-black" /></span>
          </template>
          <template v-slot:toggled>
            <span class="flex ml-4"><span>zwiń</span> <IconChevronDown class="!w-3 !h-3 rotate-180 fill-black" /></span>
          </template>
        </ButtonToggle>
        <Accordion :expanded="expandAgreements">
          <div class="space-y-4 mt-4">
            <div v-for="(agreement,idx) in texts.digital.agreements">
              <InputCheckBox @change="(v) => statementAccepted[idx] = v"
                             :checked="statementAccepted[idx]" black> {{ agreement }}
              </InputCheckBox>
            </div>
            <div>{{ texts.digital.moreover }}</div>
            <div v-for="(statement,idx) in texts.digital.statements">
              <InputCheckBox @change="(v) => statementAccepted[idx + texts.digital.agreements.length] = v"
                             :checked="statementAccepted[idx + texts.digital.agreements.length]" black>
                <b>{{ texts.digital.declare }}</b> {{ statement }}
              </InputCheckBox>
            </div>
          </div>
        </Accordion>
      </InputCheckBox>
    </div>
  </div>
</template>
