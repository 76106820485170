<template>
  <svg width="154" height="156" viewBox="0 0 154 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="77.0005" cy="78" rx="57" ry="57" stroke="#1681FF" stroke-opacity="0.25" stroke-width="14"/>
    <path d="M76.6113 20.9833C91.5222 20.8786 105.863 26.7853 116.48 37.4039C127.097 48.0226 133.119 62.4834 133.222 77.6052" stroke="url(#paint0_linear_3432_4519)" stroke-width="14" stroke-linecap="round" stroke-dasharray="4 4"/>
    <defs>
      <linearGradient id="paint0_linear_3432_4519" x1="125.538" y1="106.776" x2="27.7733" y2="50.4202" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0A66A8"/>
        <stop offset="0.755208" stop-color="#1681FF" stop-opacity="0.01"/>
        <stop offset="1" stop-color="#1681FF" stop-opacity="0"/>
      </linearGradient>
    </defs>
    <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0"
        to="360"
        dur="1.5s"
        repeatCount="indefinite" />
  </svg>
</template>
