<script setup>

</script>

<template>
  <li class="group flex flex-row items-start grow shrink basis-0">
    <div class="py-6 px-1 group-first:hidden -rotate-90">
      <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 15L0.339745 0L17.6603 0L9 15Z" fill="#9CA3AF" />
      </svg>
    </div>
    <div class="flex flex-col items-center grow shrink">
      <div class="mx-auto w-14 h-14 rounded-full flex justify-center items-center text-gray-400 bg-transparent">
        <div class="flex gap-1">
          <div class="rounded-full w-2 h-2 bg-gray-400"></div>
          <div class="rounded-full w-2 h-2 bg-gray-400"></div>
          <div class="rounded-full w-2 h-2 bg-gray-400"></div>
        </div>
      </div>
      <div class="font-extralight leading-6 text-center pt-6">

      </div>
    </div>
  </li>
</template>
