<script setup>
const submit = () => {
  return [];
}

defineExpose({
  submit
})

</script>

<template>
  <div>
    <div class="rounded shadow-box p-6">
      <div class="text-xl font-extralight mb-4">Finansowanie samochodu</div>
      <CarFinancing />

    </div>
  </div>
</template>
