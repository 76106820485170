<script setup>
import texts from "~/configs/texts";

const leasing = useLeasingParams();
const digitalOrder = useDigitalOrder();
const backend = useBackend();

const anotherAddress = ref(false);
const statements = ref(false);
const isIDcardIndefinitely = ref(false);
const isEmploymentIdindefinitely = ref(false);

const data = ref({
  client: leasing.getCurrentParams().client
})

const yesno = new Map([[0, 'NIE'], [1, 'TAK']]);

const errors = ref({
  "formCompany.nip": "",
  "formCompany.companyName": "",
  "formCompany.companyForm": "",
  "formCompany.companyRole": "",
  "formCompany.employeesAmount": "",
  "formCompany.bookkeepingType": "",
  "formCompany.lastYearIncome": "",
  "formCompany.numberOfDependents": "",
  "formPersonalCompany.name": "",
  "formPersonalCompany.surname": "",
  "formPersonalCompany.documentNumber": "",
  "formPersonalCompany.documentAuthority": "",
  "formPersonalCompany.documentExpiration": "",
  "formPersonalCompany.pesel": "",
  "formPersonalCompanySpouse.name": "",
  "formPersonalCompanySpouse.surname": "",
  "formPersonalCompanySpouse.documentNumber": "",
  "formPersonalCompanySpouse.documentAuthority": "",
  "formPersonalCompanySpouse.documentExpiration": "",
  "formPersonalCompanySpouse.pesel": "",
  "formPersonalCompany.maritalStatus": "",
  "formPersonalCompany.propertySeparation": "",
  "formCompanyAddress.postalCode": "",
  "formCompanyAddress.city": "",
  "formCompanyAddress.street": "",
  "formCompanyAddress.buildNumber": "",
  "formCompanyCorrespondAddress": "",
  "formCompanyCorrespondAddress.postalCode": "",
  "formCompanyCorrespondAddress.city": "",
  "formCompanyCorrespondAddress.street": "",
  "formCompanyCorrespondAddress.buildNumber": "",

  "formConsumer.name": "",
  "formConsumer.surname": "",
  "formConsumer.documentNumber": "",
  "formConsumer.documentAuthority": "",
  "formConsumer.documentExpiration": "",
  "formConsumer.pesel": "",
  "formConsumer.maritalStatus": "",
  "formConsumer.propertySeparation": "",
  "formConsumerSpouse.name": "",
  "formConsumerSpouse.surname": "",
  "formConsumerSpouse.documentNumber": "",
  "formConsumerSpouse.documentAuthority": "",
  "formConsumerSpouse.documentExpiration": "",
  "formConsumerSpouse.pesel": "",
  "formConsumerAddress.postalCode": "",
  "formConsumerAddress.city": "",
  "formConsumerAddress.street": "",
  "formConsumerAddress.buildNumber": "",
  "formConsumerCorrespondAddress.postalCode": "",
  "formConsumerCorrespondAddress.city": "",
  "formConsumerCorrespondAddress.street": "",
  "formConsumerCorrespondAddress.buildNumber": "",
  "formConsumerEmployment.employerName": "",
  "formConsumerEmployment.position": "",
  "formConsumerEmployment.employmentFrom": "",
  "formConsumerEmployment.salaryNetto": "",
  "formConsumerEmployment.houseHold": "",
  "formConsumerEmployment.formEmployerAddress": "",
  "formConsumerEmployment.formEmployerAddress.postalCode": "",
  "formConsumerEmployment.formEmployerAddress.city": "",
  "formConsumerEmployment.formEmployerAddress.street": "",
  "formConsumerEmployment.formEmployerAddress.buildNumber": "",
  "formStatements.statement1": "",
  "formStatements.statement2": "",
  "formStatements.statement3": "",
  "formStatements.statement4": "",
  "formStatements.statement5": "",
  "formStatements.statement6": "",
});

const resetErrors = () => {
  Object.keys(errors.value).forEach(key => errors.value[key] = "");
}

const submit = () => {
  return {
    correspondAddress: anotherAddress.value,
    statements: statements.value
  };
}

const setErrors = (err) => {
  resetErrors();
  Object.keys(err).forEach(key => errors.value[key] = err[key]);
}

defineExpose({
  submit,
  setErrors
})

const changeClientType = (type) => {
  const params = leasing.getCurrentParams();
  params.client = type;
  leasing.setCurrentParams(params);
}

const setAgreementsState = (state) => {
  statements.value = state
}

onMounted(() => {
  // console.log('🫡 FormApplication Mounted!', digitalOrder.connectedPerson.value.roles)
});

const setup = await digitalOrder.setup();

</script>

<template>
  <div>
    <div class="rounded shadow-box p-6">
      <div class="text-xl font-extralight mb-4">Dane zamawiającego</div>
      <div class="flex">
        <div class="w-full md:basis-1/2">
          <InputState :states="leasing.leasingParamsDefinition.client"
                      v-model:value="digitalOrder.client.value"
                      @change="(type) => changeClientType(type)"
          />
        </div>
      </div>
      <div v-show="digitalOrder.client.value === 'entrepreneur'">
        <div class="pb-4 pt-8 font-extralight">&nbsp;</div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputText label="NIP" v-model="digitalOrder.clientDTO.value.nip" :error="errors['formCompany.nip']" />
          <InputText label="Nazwa firmy" v-model="digitalOrder.clientDTO.value.name" :error="errors['formCompany.companyName']" />
          <InputDropDown returnSimpleValue label="Forma działalności gospodarczej" :options="setup.companyForm" v-model="digitalOrder.clientDTO.value.companyForm" :preset="digitalOrder.clientDTO.value.companyForm" :error="errors['formCompany.companyForm']" />
          <InputDropDown returnSimpleValue label="Rola pełniona w firmie" :options="setup.companyRoles" v-model="digitalOrder.connectedPerson.value.roles" :preset="digitalOrder.connectedPerson.value.roles" :error="errors['formCompany.companyRole']" />
          <InputText label="Liczba pracowników" v-model="digitalOrder.clientDTO.value.number_of_employees" :error="errors['formCompany.employeesAmount']" />
          <InputDropDown returnSimpleValue label="Forma księgowości" :options="setup.bookKeepingTypes" v-model="digitalOrder.clientDTO.value.book_keeping_type" :preset="digitalOrder.clientDTO.value.book_keeping_type" :error="errors['formCompany.bookkeepingType']" />
          <InputText label="Przychód za ostatni rok" v-model="digitalOrder.clientDTO.value.last_year_income" :error="errors['formCompany.lastYearIncome']" />
          <InputText label="Liczba osób na utrzymaniu (bez właściciela)" v-model="digitalOrder.clientDTO.value.number_of_dependents" :error="errors['formCompany.numberOfDependents']" />
        </div>

        <div class="pb-4 pt-8 font-extralight">Adres siedziby firmy</div>
        <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
          <InputText :mask="['##-###', '__-___']" label="Kod pocztowy" class="col-span-2" v-model="digitalOrder.mainAddress.value.postal_code" :error="errors['formCompanyAddress.postalCode']" />
          <InputText label="Miejscowość" class="col-span-2" v-model="digitalOrder.mainAddress.value.city" :error="errors['formCompanyAddress.city']" />
          <InputText label="Ulica" class="col-span-2" v-model="digitalOrder.mainAddress.value.street" :error="errors['formCompanyAddress.street']" />
          <InputText label="Nr budynku" v-model="digitalOrder.mainAddress.value.houseNo" :error="errors['formCompanyAddress.buildNumber']" />
          <InputText label="Nr lokalu" v-model="digitalOrder.mainAddress.value.flatNo" />
        </div>
        <div class="pt-4">
          <InputCheckBox :checked="!!anotherAddress" @change="(v) => anotherAddress = v">Mam inny adres korespondencyjny</InputCheckBox>
        </div>

        <Accordion :expanded="!!anotherAddress">
          <div class="pb-4 pt-8 font-extralight">Adres korespondencyjny</div>
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <InputText :mask="['##-###', '__-___']" label="Kod pocztowy" class="col-span-2" v-model="digitalOrder.correspondAddress.value.postal_code" :error="errors['formCompanyCorrespondAddress.postalCode']" />
            <InputText label="Miejscowość" class="col-span-2" v-model="digitalOrder.correspondAddress.value.city" :error="errors['formCompanyCorrespondAddress.city']" />
            <InputText label="Ulica" class="col-span-2" v-model="digitalOrder.correspondAddress.value.street" :error="errors['formCompanyCorrespondAddress.street']" />
            <InputText label="Nr budynku" v-model="digitalOrder.correspondAddress.value.houseNo" :error="errors['formCompanyCorrespondAddress.buildNumber']" />
            <InputText label="Nr lokalu" v-model="digitalOrder.correspondAddress.value.flatNo" />
          </div>
        </Accordion>

        <div class="pb-4 pt-8 font-extralight">Dane osobowe</div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputText label="Imię" v-model="digitalOrder.connectedPerson.value.first_name" :error="errors['formPersonalCompany.name']" />
          <InputText label="Nazwisko" v-model="digitalOrder.connectedPerson.value.last_name" :error="errors['formPersonalCompany.surname']" />
          <InputText label="Seria i numer dowodu osobistego" v-model="digitalOrder.connectedPerson.value.document_number" :error="errors['formPersonalCompany.documentNumber']" />
          <InputText label="Wydany przez" v-model="digitalOrder.connectedPerson.value.document_authority" :error="errors['formPersonalCompany.documentAuthority']" />
          <InputText :mask="['####-##-##', 'YYYY-MM-DD']" label="Data ważności dowodu osobistegoo" v-model="digitalOrder.connectedPerson.value.document_expiration_date" :error="errors['formPersonalCompany.documentExpiration']" />
          <div>
            <InputCheckBox @change="(v) => digitalOrder.connectedPerson.value.indefinitely = v"
                           :checked="!!digitalOrder.connectedPerson.value.indefinitely">Posiadam dowód ważny bezterminowo
            </InputCheckBox>
          </div>
          <InputText label="PESEL" v-model="digitalOrder.connectedPerson.value.pesel" :error="errors['formPersonalCompany.pesel']" />
          <div>
            <InputCheckBox @change="(v) => digitalOrder.connectedPerson.value.politically_exposed = v"
                           :checked="!!digitalOrder.connectedPerson.value.politically_exposed">Zajmuję eksponowane stanowisko polityczne (PEP)
            </InputCheckBox>
          </div>
          <!--          <InputText label="Kraj urodzenia" v-model="digitalOrder.connectedPerson.value.":error="errors['']" />-->
          <!--          <div class="hidden md:block"></div>-->
          <InputDropDown returnSimpleValue label="Stan cywilny" :options="setup.maritalStatuses" v-model="digitalOrder.connectedPerson.value.martial_status" :preset="digitalOrder.connectedPerson.value.martial_status" :error="errors['formPersonalCompany.maritalStatus']" />
          <InputDropDown returnSimpleValue label="Rozdzielność majątkowa"
                         :options="yesno"
                         v-model="digitalOrder.connectedPerson.value.separate_property"
                         :preset="digitalOrder.connectedPerson.value.separate_property"
                         v-show="digitalOrder.connectedPerson.value.martial_status === 'MARRIED'"
          />
        </div>

        <Accordion :expanded="!digitalOrder.connectedPerson.value.separate_property">
          <div class="pb-4 pt-8 font-extralight">Dane osobowe współmałżonka</div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText label="Imię" v-model="digitalOrder.spousePerson.value.first_name" :error="errors['formPersonalCompanySpouse.name']" />
            <InputText label="Nazwisko" v-model="digitalOrder.spousePerson.value.last_name" :error="errors['formPersonalCompanySpouse.surname']" />
            <InputText label="Seria i numer dowodu osobistego" v-model="digitalOrder.spousePerson.value.document_number" :error="errors['formPersonalCompanySpouse.documentNumber']" />
            <InputText label="Wydany przez" v-model="digitalOrder.spousePerson.value.document_authority" :error="errors['formPersonalCompanySpouse.documentAuthority']" />
            <InputText :mask="['####-##-##', 'YYYY-MM-DD']" label="Data ważności dowodu osobistegoo" v-model="digitalOrder.spousePerson.value.document_expiration_date" :error="errors['formPersonalCompanySpouse.documentExpiration']" />
            <div>
              <InputCheckBox @change="(v) => digitalOrder.spousePerson.value.indefinitely = v"
                             :checked="!!digitalOrder.spousePerson.value.indefinitely">Posiada dowód ważny bezterminowo</InputCheckBox>
            </div>
            <InputText label="PESEL" v-model="digitalOrder.spousePerson.value.pesel" :error="errors['formPersonalCompanySpouse.pesel']" />
            <div>
              <InputCheckBox @change="(v) => digitalOrder.spousePerson.value.politically_exposed = v"
                             :checked="!!digitalOrder.spousePerson.value.politically_exposed">Zajmuje eksponowane stanowisko polityczne (PEP)
              </InputCheckBox>
            </div>
            <!--            <InputText label="Kraj urodzenia" :error="errors['']" />-->
            <!--            <div class="hidden md:block"></div>-->
          </div>
        </Accordion>

        <DigitalOrderAgreementsAndStatements @change="setAgreementsState" :error="errors['formStatements.statement1']" />
      </div>
      <div v-show="digitalOrder.client.value === 'consumer'">
        <div class="pb-4 pt-8 font-extralight">Dane osobowe</div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputText label="Imię" v-model="digitalOrder.connectedPerson.value.first_name" :error="errors['formConsumer.name']" />
          <InputText label="Nazwisko" v-model="digitalOrder.connectedPerson.value.last_name" :error="errors['formConsumer.surname']" />
          <InputText label="Seria i numer dowodu osobistego" v-model="digitalOrder.connectedPerson.value.document_number" :error="errors['formConsumer.documentNumber']" />
          <InputText label="Wydany przez" v-model="digitalOrder.connectedPerson.value.document_authority" :error="errors['formConsumer.documentAuthority']" />
          <InputText :class="{'invisible opacity-0': isIDcardIndefinitely}" :mask="['####-##-##', 'YYYY-MM-DD']" label="Data ważności dowodu osobistego." v-model="digitalOrder.connectedPerson.value.document_expiration_date" :error="errors['formConsumer.documentExpiration']" />
          <div>
            <InputCheckBox @change="(v) => isIDcardIndefinitely = v">Posiadam dowód ważny bezterminowo</InputCheckBox>
          </div>
          <InputText label="PESEL" v-model="digitalOrder.connectedPerson.value.pesel" :error="errors['formConsumer.pesel']" />
          <div>
            <InputCheckBox @change="(v) => digitalOrder.connectedPerson.value.politically_exposed = v"
                           :checked="!!digitalOrder.connectedPerson.value.politically_exposed">Zajmuję eksponowane stanowisko polityczne (PEP)
            </InputCheckBox>
          </div>
          <!--          <InputText label="Kraj urodzenia" v-model="digitalOrder.connectedPerson.value.":error="errors['']" />-->
          <!--          <div class="hidden md:block"></div>-->
          <InputDropDown returnSimpleValue label="Stan cywilny" :options="setup.maritalStatuses" v-model="digitalOrder.connectedPerson.value.martial_status" :preset="digitalOrder.connectedPerson.value.martial_status" :error="errors['formConsumer.maritalStatus']" />
          <InputDropDown returnSimpleValue label="Rozdzielność majątkowa" :options="yesno"
                         v-model="digitalOrder.connectedPerson.value.separate_property"
                         :preset="digitalOrder.connectedPerson.value.separate_property"
                         :error="errors['formConsumer.propertySeparation']"
                         v-show="digitalOrder.connectedPerson.value.martial_status === 'MARRIED'"/>
        </div>

        <Accordion :expanded="!digitalOrder.connectedPerson.value.separate_property">
          <div class="pb-4 pt-8 font-extralight">Dane osobowe współmałżonka</div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputText label="Imię" v-model="digitalOrder.spousePerson.value.first_name" :error="errors['formConsumerSpouse.name']" />
            <InputText label="Nazwisko" v-model="digitalOrder.spousePerson.value.last_name" :error="errors['formConsumerSpouse.surname']" />
            <InputText label="Seria i numer dowodu osobistego" v-model="digitalOrder.spousePerson.value.document_number" :error="errors['formConsumerSpouse.documentNumber']" />
            <InputText label="Wydany przez" v-model="digitalOrder.spousePerson.value.document_authority" :error="errors['formConsumerSpouse.documentAuthority']" />
            <InputText :mask="['####-##-##', 'YYYY-MM-DD']" label="Data ważności dowodu osobistego" v-model="digitalOrder.spousePerson.value.document_expiration_date" :error="errors['formConsumerSpouse.documentExpiration']" />
            <div>
              <InputCheckBox>Posiada dowód ważny bezterminowo</InputCheckBox>
            </div>
            <InputText label="PESEL" v-model="digitalOrder.spousePerson.value.pesel" :error="errors['formConsumerSpouse.pesel']" />
            <div>
              <InputCheckBox @change="(v) => digitalOrder.spousePerson.value.politically_exposed = v"
                             :checked="!!digitalOrder.spousePerson.value.politically_exposed">Zajmuje eksponowane stanowisko polityczne (PEP)
              </InputCheckBox>
            </div>
            <!--            <InputText label="Kraj urodzenia" :error="errors['']" />-->
            <!--            <div class="hidden md:block"></div>-->
          </div>
        </Accordion>

        <div class="pb-4 pt-8 font-extralight">Adres zamieszkania</div>
        <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
          <InputText :mask="['##-###', '__-___']" label="Kod pocztowy" class="col-span-2" v-model="digitalOrder.mainAddress.value.postal_code" :error="errors['formConsumerAddress.postalCode']" />
          <InputText label="Miejscowość" class="col-span-2" v-model="digitalOrder.mainAddress.value.city" :error="errors['formConsumerAddress.city']" />
          <InputText label="Ulica" class="col-span-2" v-model="digitalOrder.mainAddress.value.street" :error="errors['formConsumerCorrespondAddress.street']" />
          <InputText label="Nr budynku" v-model="digitalOrder.mainAddress.value.houseNo" :error="errors['formConsumerCorrespondAddress.buildNumber']" />
          <InputText label="Nr lokalu" v-model="digitalOrder.mainAddress.value.flatNo" />
        </div>
        <div class="pt-4">
          <InputCheckBox :checked="!!anotherAddress" @change="(v) => anotherAddress = v">Mam inny adres korespondencyjny</InputCheckBox>
        </div>

        <Accordion :expanded="anotherAddress">
          <div class="pb-4 pt-8 font-extralight">Adres korespondencyjny</div>
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <InputText :mask="['##-###', '__-___']" label="Kod pocztowy" class="col-span-2" v-model="digitalOrder.correspondAddress.value.postal_code" :error="errors['formConsumerCorrespondAddress.postalCode']" />
            <InputText label="Miejscowość" class="col-span-2" v-model="digitalOrder.correspondAddress.value.city" :error="errors['formConsumerCorrespondAddress.city']" />
            <InputText label="Ulica" class="col-span-2" v-model="digitalOrder.correspondAddress.value.street" :error="errors['formConsumerCorrespondAddress.street']" />
            <InputText label="Nr budynku" v-model="digitalOrder.correspondAddress.value.houseNo" :error="errors['formConsumerCorrespondAddress.buildNumber']" />
            <InputText label="Nr lokalu" v-model="digitalOrder.correspondAddress.value.flatNo"  />
          </div>
        </Accordion>

        <div class="pb-4 pt-8 font-extralight">Dane o zatrudnieniu</div>
        <div class="grid grid-cols-4 md:grid-cols-8 gap-4">
          <InputText label="Nazwa pracodawcy" class="col-span-4" v-model="digitalOrder.clientDTO.value.additional_info.employerName" :error="errors['formConsumerEmployment.employerName']" />
          <InputText label="Zajmowane stanowisko" class="col-span-4" v-model="digitalOrder.clientDTO.value.additional_info.position" :error="errors['formConsumerEmployment.position']" />
          <InputText :mask="['##-###', '__-___']" label="Kod pocztowy" class="col-span-4 md:col-span-2" v-model="digitalOrder.clientDTO.value.additional_info.employer_postal_code" :error="errors['formConsumerEmployment.formEmployerAddress.postalCode']" />
          <InputText label="Miejscowość" class="col-span-4 md:col-span-6" v-model="digitalOrder.clientDTO.value.additional_info.employer_city" :error="errors['formConsumerEmployment.formEmployerAddress.city']" />
          <InputText label="Ulica" class="col-span-4" v-model="digitalOrder.clientDTO.value.additional_info.employer_street_name" :error="errors['formConsumerEmployment.formEmployerAddress.street']" />
          <InputText label="Nr budynku" class="col-span-2" v-model="digitalOrder.clientDTO.value.additional_info.employer_building_number" :error="errors['formConsumerEmployment.formEmployerAddress.buildNumber']" />
          <InputText label="Nr lokalu" class="col-span-2" v-model="digitalOrder.clientDTO.value.additional_info.employer_flat_number"  />
          <InputText :mask="['####-##-##', 'YYYY-MM-DD']" label="Zatrudnienie od" class="col-span-2 md:col-span-3" v-model="digitalOrder.clientDTO.value.additional_info.hired_from" :error="errors['formConsumerEmployment.employmentFrom']" />
          <InputText :class="{'invisible opacity-0': isEmploymentIdindefinitely}" :mask="['####-##-##', 'YYYY-MM-DD']" label="Zatrudnienie do" class="col-span-2 md:col-span-3" v-model="digitalOrder.clientDTO.value.additional_info.hired_to" />
          <div class="pt-4 col-span-4 md:col-span-2">
            <InputCheckBox @change="(v) => isEmploymentIdindefinitely = v">Umowa na czas nieokreślony</InputCheckBox>
          </div>
          <InputText label="Przeciętne wynagrodzenie miesięczne netto" class="col-span-4" v-model="digitalOrder.clientDTO.value.additional_info.average_remuneration" :error="errors['formConsumerEmployment.salaryNetto']" />
          <InputText label="Liczba osób w gospodarstwie domowym" class="col-span-4" v-model="digitalOrder.clientDTO.value.additional_info.people_in_house_hold" :error="errors['formConsumerEmployment.houseHold']" />
        </div>
        <DigitalOrderAgreementsAndStatements @change="setAgreementsState" :error="errors['formStatements.statement1']" />

      </div>
    </div>
  </div>

</template>
