<script setup>

</script>

<template>
  <div>
    <div class="rounded shadow-box p-6">
      <div class="text-xl font-extralight mb-4">Gratulacje</div>
      <div class="font-extralight">Na Twój adres e-mail wysłaliśmy umowę leasingową do podpisu.</div>
    </div>
  </div>
</template>
