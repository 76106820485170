<script setup>

import texts from "~/configs/texts";

const digitalOrder = useDigitalOrder();

const expandAgreements = ref(false);
const mailAccepted = ref(false);
const phoneAccepted = ref(false);
const allAccepted = computed(() => mailAccepted.value && phoneAccepted.value);

const acceptAll = (v) => {
  mailAccepted.value = v;
  phoneAccepted.value = v;
}

const errors = ref({});

const setErrors = (err) => {
  Object.keys(err).forEach(key => errors.value[key] = err[key]);
}

const submit = () => {
  Object.keys(digitalOrder.contactData.value).forEach(key => {
    switch (key) {
      case "email":
        errors.value[key] = validateEmail(digitalOrder.contactData.value[key]) ? "" : texts.messages.badEmail;
        break;
      case "phone":
        errors.value[key] = validatePhone(digitalOrder.contactData.value[key]) ? "" : texts.messages.badPhone;
        break;
      case "agree":
        errors.value[key] = digitalOrder.contactData.value[key] ? "" : texts.messages.allAgreementsRequired;
    }
  })

  const keys = Object.keys(errors.value);
  for (let i = 0; i < keys.length; i++) {
    if (errors.value[keys[i]]) return false;
  }

  return digitalOrder.contactData.value;
}

watch(() => allAccepted.value, (v) => digitalOrder.contactData.value.agree = v);

onMounted(() => {
  allAccepted.value = digitalOrder.contactData.value.agree;
})

defineExpose({
  submit,
  setErrors
})

</script>

<template>
  <div>
    <div class="rounded shadow-box p-6">
      <div class="text-xl font-extralight mb-4">Dane kontaktowe</div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputText type="mail" label="Adres e-mail" v-model="digitalOrder.contactData.value.email" :error="errors.email" />
        <InputText type="tel" label="Numer telefonu" v-model="digitalOrder.contactData.value.phone" :error="errors.phone" />
      </div>
      <div class="mt-6">
        <InputCheckBox @change="acceptAll" :checked="allAccepted" :error="errors.agree">Akceptuję i zaznaczam wszystkie zgody
          <ButtonToggle @toggle="(e) => expandAgreements = e">
            <template v-slot:default>[rozwiń]</template>
            <template v-slot:toggled>[zwiń]</template>
          </ButtonToggle>
          <Accordion :expanded="expandAgreements">
            <div class="space-y-4 mt-4">
              <div>
                <InputCheckBox @change="(v) => mailAccepted = v" :checked="mailAccepted"> {{ texts.form.agreements.mail.large }}</InputCheckBox>
              </div>
              <div>
                <InputCheckBox @change="(v) => phoneAccepted = v" :checked="phoneAccepted"> {{ texts.form.agreements.phone.large }}</InputCheckBox>
              </div>
            </div>
          </Accordion>
        </InputCheckBox>
      </div>
    </div>
  </div>
</template>
